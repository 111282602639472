$base-body-dark: #333333;
$base-body-light: #828282;
$base-body-v-light: #BDBDBD;
$body-background: #F4F4F4;

$brand-main: #457B9D;
$brand-light: #A8DADC;
$brand-dark: #1D3557;
$brand-v-light: #F1FAEE;
$brand-alert: #E63946;
$brand-success: #45AB7A;
$brand-accent: #F68963;
