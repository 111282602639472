@import "./styles/index.scss";

$seatSelectionWidth: 300px;
$shrunkMenu: 66px;
$containerSlide: 0.2s ease-in-out;


@media screen and (min-width: 900px) {
  .main {
    margin-left: 300px;
  }
}
