.services-list {
    position: relative;
    padding: 30px 15px;

    .service-card {
        animation:  slide-up-fade-in .2s;
    }
}

@keyframes slide-up-fade-in{
    0% {
        opacity:0;
        transform:  translate(0px, 20px)  ;
    }
    100% {
        opacity:1;
        transform:  translate(0px, 0px)  ;
    }
}