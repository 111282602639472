.churchInfo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background: white;
    box-shadow: $box-shadow;
    padding: 10px 15px;
    position: relative;
    // height: 70px;
    overflow: hidden;

    .label {
        display: flex;
        align-items: center;
        position: relative;

        .church-logo {
            height: 70px;
            width: auto;
            max-width: 20vh;
            margin-right: 10px
        }
        .church-name {
            font-weight: bold;
            font-size: 14px;
            color: #828282;
        }
        .service-name {
            font-size: 24px;
        }
    }
    
    .legend {
        display: flex;
        flex-flow: row nowrap;
        align-self: center;
        .item {
            display: flex;
            flex-flow: column;
            align-items: center;
            font-size: 14px;
            padding:10px;
        }

        .seat-border-notSelected {
            height: 2.2em;
            width: 3em;
            padding: 3px;
            display: flex;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border: 2px white dashed;
        }

        .seat-border-selected {
            height: 2.2em;
            width: 3em;
            padding: 3px;
            display: flex;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border: 2px $brand-accent dashed;
        }

        .seat {
            height: 2em;
            width: 3em;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
        }
        
        .unavailable {
            border: 2px #E0E0E0 solid;
            background-color: #E0E0E0;
            color: #555555;
            font-weight: bold;
            &::after{
                content: 'x';
            }

        }
    
        .available {
            border: 2px $brand-main solid;
            background-color: $brand-main;
        }
    
        .booked {
            border: 2px $brand-success solid;
            background-color: $brand-success;
        }
    
        .selected {
            border: 2px $brand-accent solid;
            background-color: $brand-accent;
        }
    }
    
}

@media screen and (max-width: 899px) {
    .churchInfo {
        flex-flow: column;
        justify-content: center;
        // height: 150px;
    }
}