$seatSelectionWidth: 300px;
$shrunkMenu: 66px;
$containerSlide: 0.2s ease-in-out;

.guestApp {
    position: relative;
    min-height: 94.6vh;
}

.main {
    position: relative;
    margin-left: 0;
    padding-top: 30px;
    padding-left: 30px;
    height: 100vh;
    overflow: hidden;
    margin-right: -30px;
}

.logo {
    text-align: center;
    margin-top: 10px;
}

.header {
    width: 100%;
    overflow: hidden;
    box-shadow: 2px 0px 10px #0000001f;

    header {
        background-color: $brand-main;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        height: 40px;
        overflow: hidden;

        .logo-text {
            height: 100%;
            img {
                height: 100%;
            }
        }

        .personal-info {
            color: $brand-v-light;
            border: 1px solid $brand-v-light;
        }

        .hamburger {
            width: 30px;
            div {
                transition: 0.25s ease-in-out;
                height: 2px;
                background-color: $brand-v-light;
                border-radius: 5px;
                margin: 8px 0px;
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .main {
        margin-left: 300px;
    }
}
