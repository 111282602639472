.seatSelection {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    float: left;
    box-sizing: border-box;
    background-color: $body-background;
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    // margin-top: 15px;
    box-shadow: inset 0px 3px 5px #0000001f;
    z-index: 5;

    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    border-bottom: 1px solid #d8d8d8;

    .title {
        display: none;
        font-size: 24px;
    }
    .item {
        margin-bottom: 15px;
    }
    .message {
        margin-right: 15px;
    }
    .seats {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        align-items: center;
        button {
            border-radius: 10px;
            height: 3em;
            width: 6em;
            margin-right: 15px;
        }
    }
    .save {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        button {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            .completeText {
                font-size:9px;
                margin-top: 2px;
            }
            height: 3em;
            width: 4em;
        }
    }
    button {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0px 5px;
        .text-left {
            text-align: left;
        }
        .text-right {
            text-align: right;
        }
    }
}

.service-reservations {
    position: relative;
    max-height: 100vh;
    overflow: auto;
    box-shadow: inset 5px 0 5px -5px #0000001f;
    width: 100vw;
    &.fixed {
        overflow: hidden;
    }

    .serviceMap {
        // margin-left: 30px;
        // margin-top: 30px;
        // width: 78%;
        // overflow: scroll;
        // box-shadow: inset 0 0 10px #0000001a;
        // border-radius: 2px;
        height: 59vh;
        font-size: 2.3vw;
        > * {
            position: absolute;
        }
        img {
            max-width: none;
        }
    }
}

.mapCover {
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.75);
}

.form-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    > button:not(:last-of-type) {
        margin-right: 10px;
    }
}

.seat-list {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
    justify-content: center;
}

@media screen and (min-width: 900px) {
    .service-reservations {
        width: auto;

        .serviceMap {
            height:100vh;
        }
    }
    .seatSelection {
        width: $seatSelectionWidth;
        flex-flow: column nowrap;
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 0px;
        padding-top: 30px;
        background-color: transparent;
        z-index: 1; // change
        border-bottom: 0;
        box-shadow: none;
        .message {
            width: 10em;
            margin-right: 0px;
        }
        .title {
            display: block;
        }
        .seats {
            flex-flow: column nowrap;
            button {
                margin-right: 0px;
                width: 9em;
            }
        }
        .save {
            button {
                .completeText {
                    font-size: 16px;
                }
                // display: block;
                height: 5em;
                width: 9em;
            }
        }
        button {
            padding: 0px 10px;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            .remove {
                display: none;
            }
        }
    }
    .border {
        height: 100%;
        position: fixed;
        width: $seatSelectionWidth;
        // box-shadow: 5px 0 5px -5px #0000001f;
    }
}
