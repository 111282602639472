.btn {
    padding: .55rem 1.25rem;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: 300;
    font-size: 14px;
    border-width: 0px;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    border: 1px solid #4F4F4F;
    background: #4F4F4F;
    color: white;
    &:active {
        background-color: darken($color: #4F4F4F, $amount: 15);
    }
    &.outline {
        background-color: transparent;
        color: #4F4F4F;
        &:active {
            background-color: lighten($color: #4F4F4F, $amount: 40);
        }
    }
    &.success {
        background-color: $brand-success;
        color: $brand-v-light;
        border: 1px solid $brand-success;
        &:active {
            background-color: darken($color: $brand-success, $amount: 15);
        }
        &.outline {
            background-color: transparent;
            color: $brand-success;
            &:active {
                background-color: lighten($color: $brand-success, $amount: 40);
            }
        }
    }
    &.primary {
        background-color: $brand-dark;
        color: $brand-v-light;
        border: 1px solid $brand-dark;
        &:active {
            background-color: darken($color: $brand-dark, $amount: 15);
        }
        &.outline {
            background-color: transparent;
            color: $brand-dark;
            &:active {
                background-color: lighten($color: $brand-dark, $amount: 40);
            }
        }
    }
    &.accent {
      background-color: $brand-accent;
      color: $brand-v-light;
      border: 1px solid $brand-accent;
      &:active {
          background-color: darken($color: $brand-accent, $amount: 15);
      }
      &.outline {
          background-color: transparent;
          color: $brand-accent;
          &:active {
              background-color: lighten($color: $brand-accent, $amount: 40);
          }
      }
  }
    &.link {
        background-color: transparent;
        color: $brand-main;
        border: 0px;
        text-decoration: underline;
        &:active {
            background-color:transparent;
        }
    }
  }
  
  
  // @media screen /*and (max-width: 899px)*/ {
  //   .sidebar {
  //     transform: translate3d(-$seatSelectionWidth, 0, 0);
  //   }
  //   .main {
  //     transform: translate3d(0px, 0, 0);
  //     margin-left: 0; 
  //   }
    
  //   .nav-toggle {
  //     display: none;
  //   }
    
  //   .churchreserve.menu-open {
  //     .sidebar {
  //       transform: translate3d(0px, 0, 0);
  //     }
  //     .main {
  //       transform: translate3d($seatSelectionWidth, 0, 0);
  //       .hamburger {
  //         div:first-of-type {
  //           transform: rotate(45deg);
  //         }
  //         div {
  //           transform: rotate(-45deg);
  //           margin: -2px;
  //         }
  //         div:last-of-type {
  //           display: none;
  //         }
  //       }
  //     }
  //   }
  // }