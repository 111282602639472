.modal-container{
    top:0;
    left: 0;
    position: fixed;
    z-index: 1000;
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
    overflow: hidden;
    .modal {
        width: 30em;
        background: white;
        border: 1px solid #ccc;
        // transition: 1.1s ease-out;
        box-shadow: $box-shadow;
       .modal-body {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            padding: 2rem;
       }
    }
    &.fade-in {
        opacity: 1;
        transition: opacity linear 0.15s;
    }
    &.fade-out {
        opacity: 0;
        transition: opacity linear 0.15s;
    }
    .center {
        text-align: center;
    }
    .modal-section{
        margin-bottom: 30px;
    }
}