
.alert {
    padding: 15px;
    border: 1px solid #333;
    border-radius: 2px;
    margin: 5px 0px;
    &.danger {
        padding: 15px;
        background-color: $brand-alert;
        color: white;
        margin-bottom: 20px;
        border-radius: 2px;
        border: 1px solid darken($brand-alert, 8);
        a {
            text-decoration: underline;
            color: #ffdddf;
            font-weight: 500;
        }
    }
}