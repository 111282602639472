@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500,700&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: 'montserrat';
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

a {
  text-decoration: none;
  color: $base-body-dark;
}

ol, ul {
  list-style: none;
  white-space: initial;
}

blockquote, q {
  quotes: none;
}

strong {
  font-weight: bold;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: Monsterrat, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 300;
  background-color: #F4F4F4;
}
h6, h5, h4, h3, h2, h1 {
  font-weight: 500;
  margin: .2em 0px .5em ; 
}
h6 {
  font-size: .8rem;
}
h5 {
  font-size: 1rem;
}
h4 {
  font-size: 1.4rem;
}
h3 {
  font-size: 1.8rem;
}
h2 {
  font-size: 2rem;
}
h1 {
  font-size: 2.2rem;
}


img {
  max-width: 100%;
}
