.service-card {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 15px;

    .top {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .name {
            font-size: 16px;
            width: 25%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .datetime {
            display: flex;
            justify-content: space-between;
            width: 25%
        }
        .status {
            width: 10%
        }
        .map {
            width: 20%;
        }
    }
    .bottom {
        margin-left: 10px;
    }
    .label {
        font-weight: bold;
        font-size: 12px;
        color: #828282;
    }
}

@media screen and (max-width: 899px) {
    .service-card {
        flex-flow: row wrap;
        .bottom {
            border-top: 1px solid #BDBDBD;
            padding-top: 15px;
            padding-bottom: 5px;
            margin-left: 0px;
            margin-top: 10px;
            width: 100%;
        }
        .top {
            > * {
                margin-bottom: 10px;
            }
            .name {
                width: 100%;
                text-align: center
            }
            .status {
                order: 2;
                text-align: center;
                width: 100%;
            }
    
            .datetime {
                order: 3;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
    
            
            .map {
                order: 4
            }
        }
    }

}