.form {
    h5 {
        color: #828282;
    }
    .form-control {
        border-radius: 2px;
        width: 100%;
        border: 1px solid #bdbdbd;
        color: #333;
        padding: 0.4rem;
        font-size: 14px;
        box-sizing: border-box;
        &.clickable {
            cursor: pointer;
        }
        font-family: montserrat;
        font-weight: 300;
    }

    select.form-control {
        font-size: 14px;
        -webkit-appearance: none;
        background: white;
    }
    input:focus {
        border-color: #007eff;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 0 3px rgba(0, 126, 255, 0.1);
        outline: none;
    }
    input.checkbox {
        margin-right: 8px;
        display: inline-block;
    }
    input.error {
        border-color: red;
    }
    .help-block {
        color: red;
        margin-top: 0.25rem;
    }
    .explain-block {
        margin-top: 0.25rem;
        color: gray;
    }
    img.thumbnail {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 5px;
        max-width: 64px;
        width: auto;
        height: auto;
    }

    label {
        background: border-box;
        margin-bottom: 5px;
        display: inline-block;
        font-weight: 500;
        &.checkbox {
            display: flex;
            align-items: center;
        }
    }

    .form-section {
        margin-bottom: 1em;
        position: relative;
        width: 100%;
    }

    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .inputfile + label {
        display: inline-block;
        cursor: pointer; /* "hand" cursor */
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        text-decoration: underline;
        color: $brand-main;
    }

    .title {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .form-buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        > button:not(:last-of-type) {
            margin-right: 10px;
        }
    }
}
