footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background: #333333;
    position: relative;
    bottom: 0px;
    left: 0px;
    width: 100%;

    > div {
        margin: 15px 5px;
        color: white;
    }
}

@media screen and (max-width: 899px) {
    footer {
        justify-content: space-evenly;
        > div {
            margin: 15px 0px;
        }
    }
}
